import { ApolloClient, InMemoryCache, createHttpLink, DefaultOptions, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import * as Sentry from '@sentry/nextjs';
import { SentryLink } from 'apollo-link-sentry';

const isServer = typeof window === "undefined";

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
	},
	query: {
		fetchPolicy: isServer ? 'no-cache': 'cache-first', // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
		errorPolicy: "all",
	},
};

const cache = new InMemoryCache({
	resultCaching: !isServer, // https://www.apollographql.com/docs/react/caching/cache-configuration/#resultcaching
});

/**
 * The credentials: 'include' allows cookies to be automatically sent
 * along with the request 'include' because backend is on another domain.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#cookie
 */

let fetchOptions = {};
if (isServer && process.env.NEXT_PUBLIC_WORDPRESS_GRAPHQL_IGNORE_CERTIFICATE === 'true') {
  const https = require('https');
  fetchOptions = { agent: new https.Agent({ rejectUnauthorized: false })};
}

const httpLink = createHttpLink({
	uri: `${process.env.NEXT_PUBLIC_WP_GRAPHQL_INTERNAL_URL}`,
  fetchOptions,
})

/**
 * See also:
 * https://www.apollographql.com/docs/react/data/error-handling/
 * https://www.apollographql.com/docs/react/api/link/apollo-link-error/#options
 */
const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  const errorObj = {
    graphQLErrors,
    networkError,
    response,
    operation,
  }
  console.error('\\data\\wp\\apollo\\client.js - onError', errorObj)
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureMessage(`\\data\\wp\\apollo\\client.js - onError (details) - GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`, 'error');
      console.error(`\\data\\wp\\apollo\\client.js - onError (details) - GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    Sentry.captureException(networkError);
    console.error(`\\data\\wp\\apollo\\client.js - onError (details) - Network error: ${networkError}`);
  }
});

// Apollo Link to enrich Sentry events with GraphQL data:
// https://www.npmjs.com/package/apollo-link-sentry
const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeVariables: true,
    includeError: true,
  },
});

const client = new ApolloClient({
	connectToDevTools: true,
	link: from([
    sentryLink,
    errorLink,
    httpLink
  ]),
	cache,
  assumeImmutableResults: true, // https://www.apollographql.com/docs/react/api/core/ApolloClient/#assumeimmutableresults
  ssrMode: isServer, // https://www.apollographql.com/docs/react/performance/server-side-rendering/
	defaultOptions
});

export default client;
