
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import 'src/styles/index.scss'

import React, { useEffect } from 'react';
import type { AppProps } from 'next/app'
import TagManager from 'react-gtm-module';
import { ApolloProvider } from '@apollo/client'
import client from '@/data/wp/apollo/client'
import Router from 'next/router';
import NProgress from 'nprogress';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '@/mui/createEmotionCache';
import ErrorBoundary from '@/components/ui/ErrorBoundary';
import { UIErrorProvider } from '@/utils/errors';
import { UIErrorModal } from '@/components/ui/UIErrorModal';
import { GTM_ID } from '@/utils/gtm';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  require('/src/msw-mocks');
}

// See: https://vercel.com/integrations/axiom
// See: https://www.axiom.co/docs/integrations/vercel#web-vitals
export { reportWebVitals } from 'next-axiom';

NProgress.configure( { showSpinner: false } );
Router.events.on( 'routeChangeStart', () => NProgress.start() );
Router.events.on( 'routeChangeComplete', () => NProgress.done() );
Router.events.on( 'routeChangeError', () => NProgress.done() );

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID || '' });
  }, []);
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <ErrorBoundary>
      <CacheProvider value={emotionCache}>
        <ApolloProvider client={client}>
          <UIErrorProvider>
            <Component {...pageProps} />
            <UIErrorModal />
          </UIErrorProvider>
        </ApolloProvider>
      </CacheProvider>
    </ErrorBoundary>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  