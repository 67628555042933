import { useUIError } from "@/utils/errors"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useTranslation from "next-translate/useTranslation";

export const UIErrorModal:React.FC = () => {
  const { error, removeError } = useUIError();
  const { t } = useTranslation('common');

  const handleClose = () => {
    removeError();
  }

  return (
    <Dialog open={!!error} data-testid="notification-modal">
      <DialogTitle id="responsive-dialog-title">
        {t('ui-error-modal-title', {}, {default:'Sorry'})}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <>
            {error.message}
          </>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="notification-submit-button" onClick={handleClose} autoFocus>
          {t('ui-error-modal-close', {}, {default:'Close'})}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
