const nextI18nConfig = {
  /**
   * @see https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
  */

  // Note: These locales should be also configured inside \src\mui\theme.ts for the MUI Theme.
  // IMPORTANT: see also _middleware.ts for custom language detection and redirect
  // The project assumption is that these locales are just languages (eg: 'en')
  // and DOES NOT INCLUDE THE COUNTRY (eg: 'en-US')
  // Note: german 'de' is disabled
  locales: ['default', 'en', 'es', 'fr', 'it', 'pl', 'pt', 'cs', 'ru'],
  // Default locale is set in the NEXT_PUBLIC_DEFAULT_LOCALE .env variable
  defaultLocale: 'default',
  // Set localDetection to false so that you not get redirected to "/default" as default
  localeDetection: false,

  /**
   * next-translate configuration
   * @see https://github.com/vinissimus/next-translate#3-configuration
   */
  pages: {
    "*": ["common"],
  },
}

module.exports = nextI18nConfig
