import React, { useState, useCallback, useContext, ReactNode } from 'react'

export interface UIError {
  message: string,
  status?: string,
}

export interface UIErrorContextInterface {
  error: UIError | null;
  addError: (error: UIError) => void;
  removeError: () => void;
}

export const UIErrorContext = React.createContext<UIErrorContextInterface>({
  error: null,
  addError: () => {},
  removeError: () => {},
});

export const UIErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<{ message: string, status?: string} | null>(null);
  const removeError = () => setError(null);
  const addError = (error: UIError) => setError(error);

  const contextValue = {
    error,
    addError: useCallback((error) => addError(error), []),
    removeError: useCallback(() => removeError(), []),
  }

  return <UIErrorContext.Provider value={contextValue}>
      {children}
  </UIErrorContext.Provider>;
}

export const useUIError = () => useContext(UIErrorContext);
