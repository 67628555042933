import React, { Component, ErrorInfo, ReactNode } from "react";
import NextError from 'next/error'
import { I18n } from "next-translate";
import withTranslation from "next-translate/withTranslation";

interface Props {
  children?: ReactNode;
  i18n: I18n;
}

interface State {
  hasError: boolean;
}

/**
 * Error boundaries are React components that Catch JavaScript errors
 * anywhere in their child component tree, log those errors,
 * and display a fallback UI instead of the component tree that crashed.
 *
 * Error boundaries catch errors during rendering, in lifecycle methods,
 * and in constructors of the whole tree below them.
 *
 * Do NOT CATCH errors for:
 * - Event handlers (learn more)
 * - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 *
 * https://nextjs.org/docs/advanced-features/error-handling#handling-client-errors
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { t } = this.props.i18n;

    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <NextError title={t('error-boundary-title', {}, {default: 'Sorry, an error occurred in the browser'})} statusCode={500} />
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default withTranslation(ErrorBoundary, 'common');
